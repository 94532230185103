import React, { FunctionComponent } from "react";

import Layout from "../common/layout/layout";
import SEO from "../common/seo";
import ContactForm from "../common/contact/contact-form";

const ContactPage: FunctionComponent = () => (
  <Layout>
    <SEO title="Contact" />
    <ContactForm redirectUrl="../thanks" />
  </Layout>
);

export default ContactPage;
