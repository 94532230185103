import { navigate } from "gatsby";
import React, { FunctionComponent } from "react";

import * as classes from "./contact-form.module.scss";

function encode(data: Record<string, string>) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

interface ContactFormProps {
  redirectUrl: string;
}

const ContactForm: FunctionComponent<ContactFormProps> = ({ redirectUrl }) => {
  const [state, setState] = React.useState({});

  const handleChange = (event: any) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    }).then(() => navigate(redirectUrl));
  };

  return (
    <form
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="contact"
      className={`${classes.form} lg-half-width`}
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="bot-field" onChange={handleChange} />
      <input
        type="hidden"
        name="form-name"
        value="contact"
        onChange={handleChange}
      />
      <div className={classes.field}>
        <label htmlFor="name">Name</label>
        <input
          name="name"
          className={classes.input}
          autoFocus
          required
          onChange={handleChange}
        />
      </div>
      <div className={classes.field}>
        <label htmlFor="email">E-mail</label>
        <input
          name="email"
          className={classes.input}
          type="email"
          required
          onChange={handleChange}
        />
      </div>
      <div className={classes.field}>
        <label htmlFor="message">Comment or message</label>
        <textarea
          name="message"
          className={classes.textarea}
          rows={10}
          required
          onChange={handleChange}
        />
      </div>
      <button className={classes.button}>Submit</button>
    </form>
  );
};

export default ContactForm;
